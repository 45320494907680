import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faPaintBrush, faUsers, faChalkboardTeacher, faBullhorn, faStar, faDesktop, faCogs, faUser } from '@fortawesome/free-solid-svg-icons';

const ServicesSection = () => {
  return (
    <div className="h-full top-0 overflow-auto">
      <div className="container mx-auto  ">

        <h2 className="text-3xl font-semibold text-center ">
          Our Services
        </h2>

       

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <ServiceCard
            title="Brand Strategy"
            description="Whether it's creating a brand from scratch, rebranding, or modernizing an existing one, we craft strategies that resonate."
            icon={faChartLine}
          />
          <ServiceCard
            title="Brand Identity Development"
            description="We breathe life into brands, shaping their visual identity, voice, and personality."
            icon={faPaintBrush}
          />
          <ServiceCard
            title="Customer Experience Design"
            description="Every touchpoint matters. We design seamless experiences that leave lasting impressions."
            icon={faUsers}
          />
          <ServiceCard
            title="Brand Workshops and Training"
            description="Empowering teams with the knowledge and tools they need to champion their brand."
            icon={faChalkboardTeacher}
          />
          <ServiceCard
            title="Content and Campaign Creation"
            description="From compelling storytelling to impactful campaigns, we drive engagement."
            icon={faUser}
          />
          <ServiceCard
            title="Marcom Planning and Execution"
            description="Strategic communication that amplifies your brand's message."
            icon={faBullhorn}
          />
          <ServiceCard
            title="Strategic PR"
            description="Celebrating successes and navigating challenges with finesse."
            icon={faStar}
          />
          <ServiceCard
            title="Website Design and Development"
            description="Crafting digital spaces that captivate and convert."
            icon={faDesktop}
          />
          <ServiceCard
            title="Social Media Management"
            description="Building communities and fostering authentic connections."
            icon={faUsers}
          />

        </div>
      </div>
    </div>
  );
};

const ServiceCard = ({ title, description, icon }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
      <FontAwesomeIcon icon={icon} size="4x" className='text-[#FD5F20]' />
      <h3 className="text-xl font-semibold mb-4">{title}</h3>
      <p className="text-gray-700">{description}</p>
    </div>
  );
};

export default ServicesSection;
