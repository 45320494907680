import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { api } from '../api';

// Create the AuthContext
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state

  // Fetch user if token is available in localStorage
  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');

    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      if (!storedUser) {
        fetchUser(token); // Fetch user if not in localStorage
      } else {
        setUser(JSON.parse(storedUser));
        setIsLoading(false); // Done loading when user is set from localStorage
      }
    } else {
      setIsLoading(false); // Done loading if no token
    }
  }, []);

  // Fetch the authenticated user's data using the token
  const fetchUser = async (token) => {
    try {
      const response = await api.post('/auth/me'); // API call to fetch user data
      setUser(response.data.user);
      localStorage.setItem('user', JSON.stringify(response.data.user)); // Save user data in localStorage
    } catch (err) {
      console.error('Failed to fetch user', err);
      logout(); // Optional: log out if fetching fails
    } finally {
      setIsLoading(false); // Stop loading once the process completes
    }
  };

  // Login function (unchanged)
  const login = async (email, password) => {
    try {
      const response = await api.post('/auth/login', { email, password });
      const { token, user } = response.data;

      // Store token and user data in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));

      // Set token in axios headers
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      // Update user state
      setUser(user);
    } catch (err) {
      console.error('Login failed', err);
      throw err;
    }
  };

  // Logout function (unchanged)
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
    delete axios.defaults.headers.common['Authorization']; // Remove token from headers
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
