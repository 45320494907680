import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import gr1 from '../../assets/img/Mask group (1).png'
import gr2 from '../../assets/img/Mask group (2).png'
import gr3 from '../../assets/img/Mask group (3).png'
import gr4 from '../../assets/img/Mask group (4).png'
import gr5 from '../../assets/img/Mask group (5).png'
const items = [
  {
    imgSrc: gr1,
    title: 'Learn from My Mistakes: 7 Digital Course Pitfalls to Skip',
    description: 'Discover the top digital course pitfalls to avoid from experienced creator Amy Porterfield. Skip com...',
    subt:"Amy Porterfield",
    subtdisc:"Amy Porterfield"
  },
  {
    imgSrc: gr2,
    title: 'Learn from My Mistakes: 7 Digital Course Pitfalls to Skip',
    description: 'Discover the top digital course pitfalls to avoid from experienced creator Amy Porterfield. Skip com....',
    subt:"Amy Porterfield",
    subtdisc:"Amy Porterfield"
  },
  {
    imgSrc: gr3,
    title: 'Learn from My Mistakes: 7 Digital Course Pitfalls to Skip',
    description: 'Discover the top digital course pitfalls to avoid from experienced creator Amy Porterfield. Skip com...',
    subt:"Amy Porterfield",
    subtdisc:"Amy Porterfield"
  }
  ,
  {
    imgSrc: gr4,
    title: 'Learn from My Mistakes: 7 Digital Course Pitfalls to Skip',
    description: 'Discover the top digital course pitfalls to avoid from experienced creator Amy Porterfield. Skip com...',
    subt:"Amy Porterfield",
    subtdisc:"Amy Porterfield"
  }
  ,
  {
    imgSrc: gr5,
    title: 'Learn from My Mistakes: 7 Digital Course Pitfalls to Skip',
    description: 'Discover the top digital course pitfalls to avoid from experienced creator Amy Porterfield. Skip com...',
    subt:"Amy Porterfield",
    subtdisc:"Amy Porterfield"
  }
];

function Blog() {
  return (
    <div className=" bg-gray-100 m-8 mt-28 ">
      <div className='flex flex-row'>
        <div className='flex-wrap w-1/6  m-2 p-2'>
          <h1 className='font-bold m-2 text-center'>In this article</h1>
          <p>
            Top 10 digital marketing courses in 2024
            Online Digital Marketing courses - FAQ
            Methodology
            <p> What to Do Next?</p>
          </p>
        </div>

        <div className='flex flex-col w-full'>
          <div className='bg-[#FD5F20] p-12 text-center text-3xl font-bold text-gray-200 rounded-lg'>
            <h1>What is Digital Marketing?</h1>
          </div>

          <main className="max-w-full  mx-auto ">
            <p className='w-full'>
              Digital marketing has become the go-to marketing strategy for small businesses and enterprises alike. You’ve probably heard about digital marketing before, but it encompasses many different strategies for businesses.

              <h1 className='font-bold'>This guide will discuss:</h1>
              <ul className='list-disc list-inside space-y-2 p-4 text-[#FD5F20]'>
                <li> What digital marketing is</li>
                <li>The benefits of digital marketing</li>
                <li>Types of digital marketing</li>
                <li>How to do digital marketing</li>
              </ul>
              <h1 className='font-bold'>What is digital marketing?</h1>
              Digital marketing is the use of digital channels to market products. Also known as online marketing, digital marketing promotes brands and connects them to potential customers via the internet. It takes many forms, including:

              <ul className='list-disc list-inside space-y-2 p-4 text-[#FD5F20]'>
                <li>Search engines</li>
                <li>Websites</li>
                <li>Social media</li>
                <li>Email</li>
                <li>Mobile apps</li>
              </ul>

              Digital marketing uses the same principles as traditional offline marketing but in a digital space. It relies on consumer data to find a business’s target audience and deliver the most relevant messaging possible. This is effective, but digital marketing is so popular that the biggest challenge today is learning how to stand out from the crowd.
              <br />
              Your company’s use of digital marketing depends on its model. Business-to-business (B2B) companies sell to other businesses, which requires a longer, more strategic approach. Business-to-consumer (B2C) companies sell directly to everyday customers, which requires shorter sales cycles and a larger audience. The main differences between B2B and B2C include:

              <ul className='list-disc list-inside space-y-2 p-4 text-[#FD5F20]'>
                <li>
                  B2B decisions involve multiple people, while B2C typically only involves a single person.

                </li>
                <li>B2B clients tend to have a longer decision-making process, so digital marketing should focus on building relationships.</li>
                <li>The B2C buying process is generally shorter, so this requires short-term, urgent messaging.
                </li>
                <li>B2B transactions are more driven by logic, while B2C is driven more by emotion.
                </li>
              </ul>
              Fortunately, digital marketing works for both approaches. Whether you’re targeting businesses or consumers, digital marketing will help you streamline the marketing process for results.
<h1 className='font-bold m-2'>
The benefits of digital marketing
</h1>
Regardless of whether you’re a B2B or B2C company, digital marketing helps businesses better understand their customers and deliver tailored experiences across every channel.
Digital marketing can help you get to know your audience, understand their pain points, and provide metrics that will give your marketing team credibility. Digital marketing has several benefits.

<h1 className='font-bold'>Reach a large demographic</h1>
Digital marketing allows you to reach a wider customer base with less effort. When you post an ad online, it can reach anyone, regardless of their location. You can connect with customers on the other side of the world, in multiple time zones, as seamlessly as though you were taking out an ad in the local newspaper. You can expand your market reach and grow your business in ways that would have seemed impossible before.
            <h1 className='font-bold'>Cost efficiency</h1>
            Traditional marketing channels like TV, radio, and print media typically have higher costs than digital channels. Posting on social media and creating blogs doesn’t have a cost beyond the time you spend producing the content. You can create a slick, professional website for a relatively low cost by using one of the many templated systems or paying more for a bespoke creation. You can create social media accounts for free, and partner with an email service provider (ESP) for a competitive price.
            <h1 className='text-2xl font-bold text-center m-4'>"Don't Forgret to share this post!"</h1>
<div className='flex flex-row gap-2 justify-center text-3xl'> 
  <FontAwesomeIcon icon={faFacebookF} />
  <FontAwesomeIcon icon={faWhatsapp} />
  <FontAwesomeIcon icon={faInstagram} />
  <FontAwesomeIcon icon={faLinkedinIn} />
  <FontAwesomeIcon icon={faTwitter} />
  <FontAwesomeIcon icon={faYoutube} />
   </div>

   <div className="flex items-center mt-8 text-3xl font-bold  ">
      <div className="flex-grow border-t border-2 border-gray-500 "></div>
      <span className="px-4 text-gray-700  ">Recommended For You</span>
      <div className="flex-grow border-t border-2 border-gray-500"></div>
    </div>
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 p-4">
      {items.map((item, index) => (
        <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
          <img src={item.imgSrc} alt={item.title} className="w-full h-48 object-cover" />
          <div className="p-4">
            <h3 className="text-lg font-semibold">{item.title}</h3>
            <p className=" text-sm text-gray-600">{item.description}</p>
            <h4 className='font-semi-bold'>{item.subt}</h4>
            <p className='text-sm text-gray-600'>{item.subtdisc}</p>
          </div>
        </div>
      ))}
    </div>
          </main>
        </div>

      </div>
    </div>
  );
}

export default Blog;
