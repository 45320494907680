import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import Service from './components/Pages/Service';
import BrandingPage from './components/Pages/service/Branding';
import Design from './components/Pages/service/Design';
import VideoProduction from './components/Pages/service/Video-Production';
import DigitalMarketing from './components/Pages/service/Digital-Marketing';
import Footer from './components/Footer';
import TestimonialSection from './components/TestimonialSection';
import Porti from './components/port';
import ClientFeedback from './components/ClientFeedback';
import ContactForm from './components/Contact/ContactForm';
import About from './components/Pages/About';
import Blog from './components/Pages/Blog';
import BackToTop from './components/BackToTop';
import Home from './components/Home';
import BlogPost from './components/Pages/Blog/BlogPost';
import { AuthProvider, AuthContext } from './Context/AuthContext'; // Import both AuthProvider and AuthContext
import Dashboard from './components/Adimn/Dashboard';
import Logout from './components/Adimn/Logout';
import LoginForm from './components/Adimn/LoginForm';
import NotFoundPage from './components/NotFoundPage';
import CreateContract from './components/Adimn/Contract/CreateContract';
import DashboardLayout from './components/Adimn/DashboardLayout';
import ContractList from './components/Adimn/Contract/ContractList';

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { user, isLoading } = useContext(AuthContext); // Access user and loading state from context

  // If still loading, you could show a spinner or placeholder
  if (isLoading) {
    return <div>Loading...</div>; // Show loading spinner or message
  }

  // If user exists, render children (protected content); otherwise, navigate to login
  return user ? children : <Navigate to="/" />;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className='min-h-screen'>
          <Navbar className="m-h-16" />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginForm />} /> {/* Updated route path */}
            <Route path="/blog" element={<Blog />} />
            <Route path="/blogpost" element={<BlogPost />} />
            <Route path="/service" element={<Service />} />
            <Route path="/service/branding" element={<BrandingPage />} />
            <Route path="/service/design" element={<Design />} />
            <Route path="/service/digital-marketing" element={<DigitalMarketing />} />
            <Route path="/service/video-production" element={<VideoProduction />} />
            <Route path="/logout" element={<Logout />} />

            {/* Protected Routes here */}
            <Route
              path="/admin/*" // Use wildcard to group all admin routes
              element={
                <ProtectedRoute>
                  <DashboardLayout />
                </ProtectedRoute>
              }
            >
              {/* Nested routes under the DashboardLayout */}
              <Route path="dashboard" element={<Dashboard/>} />
              <Route path="contract/create-contract" element={<CreateContract />} />
              <Route path="contract/contracts" element={<ContractList />} />
              {/* Add more nested routes as needed */}
            </Route>

            {/* Not Found Page Route */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer />
          <BackToTop />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
