import React from 'react';
import profile from '../../../assets/img/Mask group.png';
import dev from '../../../assets/img/dev.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck  } from '@fortawesome/free-solid-svg-icons';

const Design = () => {
  return (
    <div className='flex w-full min-h-screen'>
      <div className='flex flex-col flex-grow w-3/4 p-4 bg-[#FCD5C5] bg-opacity-25'>
        <div className='flex items-center justify-between'>
          <h1 className='text-3xl font-bold'>Web Development</h1>
          <button className='text-lg text-[#FD5F20] border border-[#FD5F20] rounded-lg px-4 py-2 hover:bg-[#FD5F20] hover:text-white transition-colors'>
            Try Now
          </button>
        </div>
        <div>
            <img src={dev}/>
        </div>
      </div>
      <div className='w-1/4 ml-8'> {/* Added ml-8 for margin-left */}
        <div className='h-full bg-[#FCD5C5] bg-opacity-25 border border-gray-300 rounded-lg flex flex-col items-center p-4'>
          {/* Profile Photo */}
          <img
            src={profile}
            alt='Profile'
            className='w-24 h-24 rounded-full object-cover mb-4'
          />
          {/* Name */}
          <h2 className='text-xl font-semibold mb-2'>Defar Gobeze</h2>
          {/* Description */}
          <p className='text-gray-700'>
          Experience the difference with Defar. Our team will craft a unique website tailored to your exact specifications,
           ensuring a seamless and visually stunning front end along with a robust and efficient back end.
          </p>
          <div className='flex flex-col m-8'>
           <div> <span ><FontAwesomeIcon className='text-xs text-[#FD5F20] p-1 mr-1  rounded-full border  border-[#FD5F20]' icon={ faCheck }/></span>Prototyping</div>
           <div><span ><FontAwesomeIcon className='text-sm text-[#FD5F20] p-1 mr-1  rounded-full border  border-[#FD5F20]' icon={ faCheck }/></span>Testing & Debugging</div>
           <div><span ><FontAwesomeIcon className='text-sm text-[#FD5F20] p-1 mr-1 rounded-full border border-[#FD5F20]' icon={ faCheck }/></span>Wireframing</div>
           <div><span ><FontAwesomeIcon className='text-sm text-[#FD5F20] p-1 mr-1 rounded-full border border-[#FD5F20]' icon={ faCheck }/></span>SEO & Analytics      </div>    


</div>
        </div>
      </div>
    </div>
  );
};

export default Design;
