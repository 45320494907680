import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faBars } from '@fortawesome/free-solid-svg-icons';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import AuthModal from './Adimn/AuthModal'; 
import { AuthContext } from '../Context/AuthContext';
import UserAccountModal from './Adimn/UserAccountModal';

const Navbar = () => {
  const { user, logout } = useContext(AuthContext) || {}; // Handle undefined context
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown

  const cln = "block py-2 m-2 text-white lg:border-none py-2 cursor-pointer hover:text-gray-300 shadow-lg";

  const toggleNavbar = () => setIsOpen(prev => !prev);
  const handleLogout = () => {
    logout(); // Call logout function from context
    setIsDropdownOpen(false); // Close dropdown after logout
  };

  const handleModalClose = () => setIsModalOpen(false);
  const toggleDropdown = () => setIsDropdownOpen(prev => !prev); // Toggle dropdown visibility

  return (
    <nav className="bg-gradient-to-r from-[#FD5F20] via-[#FD5F20] to-[#FD5F20] shadow-lg fixed top-0 left-0 right-0 z-50 h-20">
      <div className="container mx-auto m-2">
        <div className="flex justify-between items-center mr-4 ml-4 text-white ">
          <div className='mb-4'>
            <h1 className="text-4xl font-bold text-gradient bg-clip-text cursor-pointer">
              <ScrollLink to="hero" smooth={true} duration={500}>ATO</ScrollLink>
            </h1>
          </div>

          <div className="font-bold text-gradient bg-clip-text hidden md:block">
            <ul className="flex space-x-4">
              <li>
                <RouterLink to="/?section=About" className="hover:text-gray-300 cursor-pointer">About Us</RouterLink>
              </li>
              <li>
                <RouterLink to="/Service" className="hover:text-gray-300 cursor-pointer">Services</RouterLink>
              </li>
              <li>
                <RouterLink to="/?section=ContactForm" smooth={true} duration={500} className="hover:text-gray-300 cursor-pointer">Contact</RouterLink>
              </li>
              <li>
                <RouterLink to="/blog" className="hover:text-gray-300 cursor-pointer">Blog</RouterLink>
              </li>
              <li>
                <FontAwesomeIcon icon={faGlobe} />
                <a href="#" className="hover:text-gray-200">EN</a>
              </li>
              <li className="relative">
                {user ? (
                  <>
                    <button onClick={toggleDropdown} className="hover:underline" aria-label="User Details">{user.email}</button>
                    {isDropdownOpen && (
                      <UserAccountModal
                        user={user}
                        onLogout={handleLogout}
                        onClose={() => setIsDropdownOpen(false)}
                      />
                    )}
                  </>
                ) : (
                  <button onClick={() => setIsModalOpen(true)} className="hover:underline" aria-label="Login">Login</button>
                )}
              </li>
            </ul>
          </div>

          <AuthModal isOpen={isModalOpen} onClose={handleModalClose} />

          <div className="md:hidden">
            <button 
              className="text-white font-bold text-2xl focus:outline-none" 
              onClick={toggleNavbar} 
              aria-label="Toggle Navbar"
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
        </div>

        {isOpen && (
          <div className="md:hidden bg-gradient-to-r w-full from-[#FD5F20] via-[#FD5F20] to-[#FD5F20] shadow-lg">
            <ul className="ml-4 shadow-lg">
              <li>
                <ScrollLink to="About" smooth={true} duration={500} offset={-64} className={cln}>About Us</ScrollLink>
              </li>
              <li>
                <ScrollLink to="services" smooth={true} duration={500} offset={-64} className={cln}>Services</ScrollLink>
              </li>
              <li>
                <ScrollLink to="ContactForm" smooth={true} duration={500} className={cln}>Contact</ScrollLink>
              </li>
              <li>
                <RouterLink to="/blog" className={cln}>Blog</RouterLink>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
