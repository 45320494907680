import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUser, FaChevronDown, FaNewspaper, FaBlog, FaEnvelope, FaFileContract } from 'react-icons/fa';

const Sidebar = () => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isNewsMenuOpen, setIsNewsMenuOpen] = useState(false);
  const [isBlogsMenuOpen, setIsBlogsMenuOpen] = useState(false);
  const [isContactMenuOpen, setIsContactMenuOpen] = useState(false);
  const [isContractMenuOpen, setIsContractMenuOpen] = useState(false);

  const toggleUserMenu = () => setIsUserMenuOpen(!isUserMenuOpen);
  const toggleNewsMenu = () => setIsNewsMenuOpen(!isNewsMenuOpen);
  const toggleBlogsMenu = () => setIsBlogsMenuOpen(!isBlogsMenuOpen);
  const toggleContactMenu = () => setIsContactMenuOpen(!isContactMenuOpen);
  const toggleContractMenu = () => setIsContractMenuOpen(!isContractMenuOpen);

  return (
    <div className="h-full  bg-white text-[#FD5F20] w-64 space-y-6 py-7 px-2">
      <h1 className="text-3xl text-center font-bold">Admin Panel</h1>

      {/* User Management Section */}
      <div>
        <button 
          className="flex items-center justify-between w-full py-2 px-4 text-left rounded hover:bg-[#FD5F20] hover:bg-opacity-25"
          onClick={toggleUserMenu}
        >
          <span className="flex items-center">
            <FaUser className="mr-2" /> User Management
          </span>
          <FaChevronDown className={`transition-transform ${isUserMenuOpen ? 'rotate-180' : ''}`} />
        </button>
        {isUserMenuOpen && (
          <div className="pl-8 space-y-2">
            <Link to="/user-registration" className="block py-2 px-4 rounded hover:bg-[#FD5F20] hover:bg-opacity-25">User Registration</Link>
            <Link to="/user-list" className="block py-2 px-4 rounded hover:bg-[#FD5F20] hover:bg-opacity-25">User List</Link>
          </div>
        )}
      </div>

      {/* News Management Section */}
      <div>
        <button 
          className="flex items-center justify-between w-full py-2 px-4 text-left rounded hover:bg-[#FD5F20] hover:bg-opacity-25"
          onClick={toggleNewsMenu}
        >
          <span className="flex items-center">
            <FaNewspaper className="mr-2" /> News Management
          </span>
          <FaChevronDown className={`transition-transform ${isNewsMenuOpen ? 'rotate-180' : ''}`} />
        </button>
        {isNewsMenuOpen && (
          <div className="pl-8 space-y-2">
            <Link to="/post-news" className="block py-2 px-4 rounded hover:bg-[#FD5F20] hover:bg-opacity-25">Post News</Link>
            <Link to="/news-list" className="block py-2 px-4 rounded hover:bg-[#FD5F20] hover:bg-opacity-25">News List</Link>
          </div>
        )}
      </div>

      {/* Blogs Management Section */}
      <div>
        <button 
          className="flex items-center justify-between w-full py-2 px-4 text-left rounded hover:bg-[#FD5F20] hover:bg-opacity-25"
          onClick={toggleBlogsMenu}
        >
          <span className="flex items-center">
            <FaBlog className="mr-2" /> Blogs Management
          </span>
          <FaChevronDown className={`transition-transform ${isBlogsMenuOpen ? 'rotate-180' : ''}`} />
        </button>
        {isBlogsMenuOpen && (
          <div className="pl-8 space-y-2">
            <Link to="/post-blogs" className="block py-2 px-4 rounded hover:bg-[#FD5F20] hover:bg-opacity-25">Post Blog</Link>
            <Link to="/blog-list" className="block py-2 px-4 rounded hover:bg-[#FD5F20] hover:bg-opacity-25">Blog List</Link>
          </div>
        )}
      </div>

      {/* Contact Management Section */}
      <div>
        <button 
          className="flex items-center justify-between w-full py-2 px-4 text-left rounded hover:bg-[#FD5F20] hover:bg-opacity-25"
          onClick={toggleContactMenu}
        >
          <span className="flex items-center">
            <FaEnvelope className="mr-2" /> Contact Management
          </span>
          <FaChevronDown className={`transition-transform ${isContactMenuOpen ? 'rotate-180' : ''}`} />
        </button>
        {isContactMenuOpen && (
          <div className="pl-8 space-y-2">
            <Link to="/manage-contact" className="block py-2 px-4 rounded hover:bg-[#FD5F20] hover:bg-opacity-25">Manage Contact Us</Link>
          </div>
        )}
      </div>

      {/* Contract Management Section */}
      <div>
        <button 
          className="flex items-center justify-between w-full py-2 px-4 text-left rounded hover:bg-[#FD5F20] hover:bg-opacity-25"
          onClick={toggleContractMenu}
        >
          <span className="flex items-center">
            <FaFileContract className="mr-2" /> Contract Management
          </span>
          <FaChevronDown className={`transition-transform ${isContractMenuOpen ? 'rotate-180' : ''}`} />
        </button>
        {isContractMenuOpen && (
          <div className="pl-8 space-y-2">
            <Link to="/admin/Contract/create-contract" className="block py-2 px-4 rounded hover:bg-[#FD5F20] hover:bg-opacity-25">Create Contract</Link>
            <Link to="/admin/contract/contracts" className="block py-2 px-4 rounded hover:bg-[#FD5F20] hover:bg-opacity-25">Contract List</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
