import React, { useState } from 'react';
import BrandingPage from './service/Branding';
import DigitalMarketing from './service/Digital-Marketing';
import VideoProduction from './service/Video-Production';
import Design from './service/Design';

const Service = () => {
    const [selectedService, setSelectedService] = useState('branding');

    const renderServiceContent = () => {
        switch (selectedService) {
            case 'branding':
                return <BrandingPage />;
            case 'design':
                return <Design />;
            case 'digital-marketing':
                return <DigitalMarketing />;
            case 'video-production':
                return <VideoProduction />;
            default:
                return null;
        }
    };

    return (
        <div className='min-h-screen px-4 sm:px-6 lg:px-8 pt-20'>
            <div className='flex flex-wrap gap-4 m-4 justify-center'>
                <button
                    onClick={() => setSelectedService('branding')}
                    className='bg-[#FD5F20] text-white px-4 py-2 rounded-lg shadow-md hover:bg-[#e55a1e] transition-colors'
                >
                    Branding
                </button>
                <button
                    onClick={() => setSelectedService('design')}
                    className='bg-[#FD5F20] text-white px-4 py-2 rounded-lg shadow-md hover:bg-[#e55a1e] transition-colors'
                >
                    Design
                </button>
                <button
                    onClick={() => setSelectedService('digital-marketing')}
                    className='bg-[#FD5F20] text-white px-4 py-2 rounded-lg shadow-md hover:bg-[#e55a1e] transition-colors'
                >
                    Digital Marketing
                </button>
                <button
                    onClick={() => setSelectedService('video-production')}
                    className='bg-[#FD5F20] text-white px-4 py-2 rounded-lg shadow-md hover:bg-[#e55a1e] transition-colors'
                >
                    Video Production
                </button>
            </div>
            
            <div className='pt-4'>
                {renderServiceContent()}
            </div>
        </div>
    );
};

export default Service;
